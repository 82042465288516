































































import KnowledgePanel from '@/views-v2/KnowledgePanel.vue'
import SimilarQueries from '@/views-v2/search/SimilarQueries.vue'
import SegmentedWebAppSidebar from '@c/features/navigation-sidebar/pages/SegmentedWebAppSidebar.vue'
import Resource from '@c/models/Resource'
import Topic from '@c/models/Topic'
import User from '@c/models/User'
import WorkspaceMember from '@c/models/WorkspaceMember'
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import Workspace from '../../../models/Workspace'
import ResourceTypeTabs from '@c/shared/molecules/filters/ResourceTypeTabs.vue'
import GlobalSearchBar from '@c/shared/molecules/inputs/GlobalSearchBar.vue'

export default Vue.extend({
  name: 'DefaultApplicationShell',
  components: {
    KnowledgePanel,
    SimilarQueries,
    SegmentedWebAppSidebar,
    ResourceTypeTabs,
    GlobalSearchBar
  },
  props: {
    workspace: {
      type: Object as PropType<Workspace>,
      default: undefined
    },
    user: {
      type: Object as PropType<User>,
      required: true
    },
    currentWorkspaceMember: {
      type: Object as () => WorkspaceMember,
      required: true
    },
    currentPageWorkspaceMember: {
      type: Object as () => WorkspaceMember,
      default: undefined
    },
    activeIntegrations: {
      type: Array,
      default: function () {
        return []
      }
    },
    allWorkspaces: {
      type: Array as PropType<Workspace[]>,
      default: undefined
    },
    noDivider: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    searchResults: {
      type: Object as () => {
        resource?: Resource[]
        workspace?: Workspace[]
        user?: User[]
        topic?: Topic[]
      },
      default: function () {
        return {
          resource: [],
          workspace: [],
          user: [],
          topic: []
        }
      }
    },
    initialActive: {
      type: Number,
      default: undefined
    },
    searchHistory: {
      type: Array as () => { query: string }[] | undefined,
      default: undefined
    }
  },
  data() {
    return {
      workspaceContext:
        this.workspace ||
        (this.allWorkspaces ? this.allWorkspaces[0] : undefined)
    }
  },
  computed: {
    ...mapState({
      modalOpen: (state) => state.webapp.modalOpen,
      extractedEntities: (state) => state.filters.extractedEntities,
      insights: (state) => state.filters.insights,
      searchEmpty: (state) => state.filters.searchEmpty,
      searchErrored: (state) => state.filters.searchErrored,
      sidebarWidth: (state) => state.webapp.sidebarWidth
    }),
    showHeader() {
      return ![
        'home',
        'member-profile',
        'meeting-outputs',
        'portfolio-create',
        'portfolio-edit'
      ].includes(this.$route.name)
    },
    headerTitle() {
      return (
        {
          search: 'Search',
          meetings: 'Your meetings',
          portfolio: 'Portfolio',
          'portfolio-insights': 'Portfolio insights',
          prospecting: 'Prospecting',
          presentations: 'Presentations',
          analytics: 'User analytics',
          curation: 'Curation',
          'ws-settings': 'Settings',
          chat: 'PortfolioGPT',
        } as any
      )[this.$route.name]
    },
    isHomePage() {
      return this.$route.name === 'home'
    },
    showSearchNavigation() {
      return (
        ['search', 'portfolio', 'home'].includes(this.$route.name) &&
        !this.$route.params.ori_id
      )
    },
    isSearch() {
      return this.$route.name === 'search'
    },
    knowledgePanelEnabled() {
      return this.insights !== undefined && this.extractedEntities !== undefined
    },
    scrollContainerStyling() {
      return `overflow-y: ${
        this.modalOpen || this.isHomePage || this.$route.meta.noscroll
          ? 'hidden'
          : 'scroll'
      }; overflow-x: hidden; `
    },
    workspaceGridLayout() {
      return {
        'full-height': true,
        expanded: false,
        'grid-layout': true
      }
    },
    showKnowledgePanel() {
      return (
        this.$route.name === 'search' &&
        !(
          'tab' in this.$route.params &&
          ['slides', 'videos', 'people'].includes(this.$route.params.tab)
        ) &&
        !this.searchEmpty &&
        !this.searchErrored
      )
    }
  },
  watch: {
    allWorkspaces(newVal) {
      this.workspaceContext =
        newVal.find((x) => x.uuid === this.workspaceContext?.uuid) ||
        this.workspace
    },
    workspaceContext(newVal, oldVal) {
      if (newVal?.uuid !== oldVal?.uuid) {
        return this.$emit('workspaceChanged', newVal)
      }
      this.$emit('home')
    },
    workspace(newVal, oldVal) {
      if (newVal?.uuid !== oldVal?.uuid) {
        Vue.set(this, 'workspaceContext', newVal)
      }
    }
  },
  methods: {
    ...mapActions(['setSidebarWidth'])
  }
})
